import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useRouteMatch,
  // useParams
} from "react-router-dom";

// import Shipment from './Components/Shipment'
// import Location from './Components/Location'
import Manager from './Components/Manager/Manager'
// import Carrier from './Components/Carrier/Carrier'
import FourOhFour from './Components/FourOhFour/FourOhFour'
import LandingPage from './Components/Landing/LandingPage'
import BadUUID from './Components/BadUUID/BadUUID'
import BreakoutCarrier from './Components/BreakoutCarrier/BreakoutCarrier';
import { UserPermissionsProvider } from './context/UserPermissionsProvider';

// import A from './Components/A'

// import axios from 'axios';



import './App.css';

import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  console.log('MainApp')
  return (
    <Router>
      <UserPermissionsProvider>
        <div className="App">
          {/* <p>Powered By ShippersEdge TMS</p> */}
          <Switch>
            {/* <Route path="/testa/" component={A} /> */}
            <Route path="/manager/:locationId/:UUID/" component={Manager} />
            <Route path="/carrier/:driverCode/" component={BreakoutCarrier} />
            {/* <Route path="/schedule/shipment/:shipmentId/:pickupOrDelivery" children={<Shipment />} />
          <Route path="/schedule/shipment/:shipmentId" children={<Shipment />} />
          <Route path="/schedule/location/:locationId" children={<Location />} /> */}
            <Route exact path="/baduuid/" component={BadUUID} />
            <Route exact path="/" component={LandingPage} />
            <Route component={FourOhFour} />
          </Switch>

        </div >
      </UserPermissionsProvider>
    </Router>
  );
}

export default App;
