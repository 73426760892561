import React, { createContext, useState, useEffect, useContext } from 'react'

const UserPermissionsContext = createContext()

export const UserPermissionsProvider = ({ children }) => {
    const humanFriendlyDateTime = new Date().toLocaleString()
    // console.log('UserPermissionsProvider', humanFriendlyDateTime)
    const [userPerms, setUserPerms] = useState([])

    const getPermsArray = (obj, path, defaultValue = []) => {
        console.log('fetchPermissions getPermsArray', path)
        try {
            const value = path.split('.').reduce((acc, key) => acc?.[key], obj)
            return typeof value === 'string' ? value.split(',') : defaultValue
        } catch {
            return defaultValue
        }
    }

    const fetchPermissions = async (cftoken) => {
        const response = await fetch(
            `https://api.shippersedge.com/authenticate/cfSession/?sessionId=${cftoken}`
        )
        const data = await response.json()
        console.log('fetchPermissions', data)
        if (data?.auth && data?.auth.userPerms) {
            let newPermissions = []
            newPermissions = getPermsArray(data, 'auth.userPerms')
            newPermissions = newPermissions.concat(getPermsArray(data, 'auth.subuserPermsList'))
            newPermissions = newPermissions.concat(getPermsArray(data, 'globalperms'))
            newPermissions = newPermissions.concat(getPermsArray(data, 'auth.DepartmentSettings.permissions'))
            // dedupe newPermissions
            newPermissions = [...new Set(newPermissions)]
            if (JSON.stringify(newPermissions) !== JSON.stringify(userPerms)) {
                setUserPerms(newPermissions)
            }
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const cftoken = urlParams.get('cftoken')
        if (cftoken) {
            fetchPermissions(cftoken)
        }

        const tenMinutes = 600000

        // Periodically refetch permissions
        const interval = setInterval(() => {
            if (cftoken) {
                fetchPermissions(cftoken)
            }
        }, tenMinutes)

        return () => clearInterval(interval)
    }, []) // Empty dependency array to run only on mount

    return (
        <UserPermissionsContext.Provider value={userPerms}>
            {children}
        </UserPermissionsContext.Provider>
    )
}

export const useUserPermissions = () => {
    return useContext(UserPermissionsContext)
}